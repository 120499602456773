import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"


export default function Podcasts() {
	return (
		<Layout>
			<article className="links">
				<h1>Favorite Podcasts, Books and Videos</h1>

				<div className="flex-container">
					<div className="flex-child">
						<h2>Software Engineer</h2>
						<ul>
							<li className="podcast"><Link to="https://engineeringmanagementinstitute.org/the-podcast/">The Engineering Career Coach</Link></li>
							<li className="watch"><Link to="https://www.youtube.com/channel/UC1SKRYr2Emfbas-8ZA9GYng">LGTM: shipit</Link></li>
						</ul>
					</div>
					<div className="flex-child">
						<h2>Leadership</h2>
						<ul>
							<li className="book"><Link to="https://www.amazon.ca/Managers-Path-Leaders-Navigating-Growth/dp/1491973897">The Manager's Path</Link></li>
							<li className="book"><Link to="https://www.amazon.ca/Making-Manager-What-Everyone-Looks/dp/0735219567/">The Making of a Manager</Link></li>
							<li className="book"><Link to="https://www.amazon.ca/Radical-Candor-Kick-Ass-Without-Humanity/dp/1250103509">Radical Candor</Link></li>

							<li className="podcast"><Link to="https://coachingforleaders.com/">Coaching for Leaders</Link></li>
							<li className="podcast"><Link to="https://www.manager-tools.com/all-podcasts">Manager Tools</Link></li>
						</ul>
					</div>
					<div className="flex-child">
						<h2>Financial Freedom</h2>
						<ul>
							<li className="podcast"><Link to="https://www.choosefi.com/listen/choose-fi-podcast/">Choose FI</Link></li>
							<li className="podcast"><Link to="https://millionairesunveiled.com/podcast/">Millionaires Unveiled</Link></li>
						</ul>
					</div>
					<div className="flex-child">
						<h2>Self Improvement</h2>
						<ul>
							<li className="podcast"><Link to="https://gretchenrubin.com/podcasts/">Happier</Link></li>
						</ul>
					</div>
					<div className="flex-child">
						<h2>Photography</h2>
						<ul>
							<li className="watch"><Link to="https://www.youtube.com/watch?v=-mfG2yXI3To&ab_channel=FstoppersLive">Fstoppers Live</Link></li>
							<li className="watch"><Link to="https://www.youtube.com/channel/UCkJld-AoXurbT2jDnfM8qiA">Nigel Danson</Link></li>
							<li className="watch"><Link to="https://www.youtube.com/channel/UCujr_HrX3ThMb34DV5YXHIg">Mark Denney</Link></li>
						</ul>
					</div>
				</div>
			</article>
		</Layout>
	)
}
 // 
 // 
 // 
